import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Paginations from "@/components/paginations";
import { ProjectData } from "@/data";
import { Link, useStaticQuery, graphql } from 'gatsby'

import ProjectCard from "@/components/project-card";

const ProjectOne = () => {

  const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(
          sort: {order: ASC, fields: frontmatter___date}
          filter: {frontmatter: {type: {eq: "Completed"}}}
        ) {
          nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              image
              description
            }
          }
        }
      }
    `)

  const projects = data.allMarkdownRemark.nodes

  return (
    <section className="commonSection blogPage">

      <Container>
        <Row>
          {projects.map((post, index) => (
            <Col lg={4} sm={12} md={6} key={index}>
              <ProjectCard data={post.frontmatter} slug={post.fields.slug}/>
            </Col>
          ))}
        </Row>
        {/* <Paginations /> */}
      </Container>
    </section>
  );
};

export default ProjectOne;

